import React, { Component } from 'react';
import { Link } from "react-router-dom";

import $ from 'jquery';
import {
  Row,
  Col,
  Modal,
  Table,
  OverlayTrigger,
  ButtonToolbar,
  Tooltip,
  Button
} from "react-bootstrap";
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import Pagination from "react-js-pagination";
import { useParams, useNavigate } from 'react-router-dom';
import { scrollToTopValidate,PageTitle } from "../Helpers/SettingHelper";

import { apiUrl, carImageUrl, currency, dummycarUrl} from'../Config/Config';
import axios from 'axios';

import moment from 'moment';
const withRouter = WrappedComponent => props => {
	const params = useParams();
	const  navigate = useNavigate();
	// etc... other react-router-dom v6 hooks
  
	return (
	  <WrappedComponent
		{...props}
		params={params}
		navigate ={navigate}
		// etc...
	  />
	);
  };



class List extends Component {
  
  constructor(props)
  {
    super(props); 
    this.state = {
      couponlist:'',
      editable: false,
      priorityid:'',
      priorityvalue:'',
      activePage: 1,
      totalRecord: '',
      search_all:''
    };

     var admin_id = localStorage.getItem("admin_id");
      var qs = require('qs');
      var postobject = {
        activePage: 1,
        search_all: '',
        admin_id: admin_id
      };

    
    axios.post(apiUrl+"coupon/list",qs.stringify(postobject)).then(res => {
      if(res.data.status === 'success'){
       
          this.setState({  couponlist: res.data, totalRecord: res.data.records_count,data_status: res.data.status}, function() {
          
            this.couponlist();
          });

      }
    });
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    }

   
   componentDidMount() {
      document.title = PageTitle('Coupon');
      if(localStorage.getItem('admin_id') === null){
      this.props.history.push('/');
      }
   }
   listloadedData(postobject=''){ 
    var qs = require('qs');
    if(postobject!=''){
      var postobject = postobject
    }else{
     
      var postobject = {
      activePage: 1,
      search_all: '',
      admin_id: localStorage.getItem('admin_id')  
      };
    }
 
    axios.post(apiUrl+"coupon/list",qs.stringify(postobject)).then(res => {
      if(res.data.status === 'success'){
       
          this.setState({  couponlist: res.data, totalRecord: res.data.records_count,data_status: res.data.status}, function() {
          
            this.couponlist();
          });

      }
    });
}
   handlePageChange(pageNumber) {
     // console.log(`active page is ${pageNumber}`);
      this.setState({activePage: pageNumber});
      var qs = require('qs');
      var admin_id = localStorage.getItem("admin_id");
      var postobject = {
        activePage: pageNumber,
        search_all: this.state.search_all,
        admin_id: admin_id
      };
    this.listloadedData(postobject)
    }

    handleInputChange(event) {
    const {name, value} = event.target;
    var postObject = {             
      activePage   : 1,
      search_all : value,
      admin_id: localStorage.getItem('admin_id')           
    };        
    this.setState({
      [name]: value
    },function(){
      this.listloadedData(postObject)
    });
    
  }
   handleFormSubmit = () => {
        
          this.setState({Loading:true});
          var qs = require('qs');
          const formPayload = this.state;
          var admin_id = localStorage.getItem("admin_id");
          var postObject = {             
              activePage : 1,
              search_all : formPayload.search_all,
              admin_id  : admin_id         
          };
          //console.log(postObject);
          this.props.getCouponList(qs.stringify(postObject));
        
    }

  //  componentWillReceiveProps(Props){

  //   if (Object.keys(Props.couponlist).length > 0) {
  //     if (Props.couponlist[0]["status"] === "success") {
  //      /* this.setState({
  //         couponlist: Props.couponlist[0]["couponlist"],
  //       });*/
  //        this.setState({ totalRecord : Props.couponlist[0].records_count, couponlist: Props.couponlist[0]["couponlist"] }, function() {
  //           this.couponlist();
  //         });
  //     }
  //   }
 

  //  }

   confirmPopup = (id, status) => {
    var status = status;
    
      if(status == 'Inactive'){
        status = "Active";
      }else if(status == 'Active'){
        status = "Inactive";
      }else{
        status="Delete";
      }
      console.log(status,'statusnew')
    this.setState({selectid: id, selectedStatus: status})	
    $('.confirm-action').addClass('show');		
  }
  couponlist() {
    var couponlist = this.state.couponlist;
    if (couponlist != "undefined" && couponlist != null) {
      if (couponlist.status == "success") {
        if (Object.keys(couponlist).length > 0) {
          const couponlistDetails = couponlist.couponlist.map(
            (couponlist, Index) => {
              let sno = Index+1;
              if(couponlist.coupon_status == 'A'){
                var status = 'Active';
              }else{
                  status = 'Inactive';
              }
              return (
                <tr key={couponlist.coupon_id}>
                <td>{sno}</td>
                  <td>{couponlist.code}</td>
                  <td>{couponlist.discount}</td>
                  <td>{moment(couponlist.valid_from).format('MM/DD/YYYY')}</td>
                  <td>{moment(couponlist.valid_to).format('MM/DD/YYYY')}</td>
                  <td>{couponlist.total_usage}</td>
                  <td>{moment(couponlist.created_on).format('MM/DD/YYYY')}</td>
                  <td>{status}</td>
                  <td className='actiongroup'> <Link to={{ pathname: '/coupon-edit/'+couponlist.coupon_id}} className="" title="Edit">  <i className="ft-edit-3" aria-hidden="true"></i></Link>
                  {(status == 'Active'?<Link onClick={this.confirmPopup.bind(this, couponlist.coupon_id, status)} className="" title="Status"><i className="ft-unlock" aria-hidden="true"></i></Link>:<Link onClick={this.confirmPopup.bind(this, couponlist.coupon_id, status)} className="" title="Status"><i className="fa ft-lock" aria-hidden="true"></i></Link>)}  
                  <Link onClick={this.confirmPopup.bind(this,couponlist.coupon_id)} className="" title="Delete"><i className="ft-trash-2" aria-hidden="true"></i></Link>
                  </td>
                </tr>
              );
            }
          );
          return couponlistDetails ;
        }
      } else {
        return (
          <tr>
            <td colspan="6" className="v-align-nr">
              No Result
            </td>
          </tr>
        );
      }
    }
  }
  activatecoupons(){
    let stat = ''
    if(this.state.selectedStatus ==='Inactive'){
      stat = "I";
    }else{
      stat = "A";
    } 
    var qs = require('qs');
    var postObject = {
      admin_id : localStorage.getItem("admin_id"),
      id  :this.state.selectid,
      status: stat
    };

  axios.post(apiUrl+"coupon/change_status",qs.stringify(postObject)).then(res => {
    if(res.data.status === 'success'){
    
      this.listloadedData();
      $('.success_message').html('<div class="status_sucess"><h3>'+ res.data.message +'</h3></div>');
   
      setTimeout(
        function() {
       
          $('.success_message').html('');
           
        }
        .bind(this),
        3000
        );
    }
  }); 
}
  deletetab(){
    var qs = require('qs');
    var postObject = {
      admin_id : localStorage.getItem("admin_id"),
      id  :this.state.selectid,
    };
    
    axios.post(apiUrl+"coupon/delete",qs.stringify(postObject)).then(res => {
    if(res.data.status === 'success'){  
      $('.success_message').html('<div class="status_sucess"><h3>'+ res.data.message +'</h3></div>');
        setTimeout(
          function() {
            $('.success_message').html('');
             
          }
          .bind(this),
          3000
          );     
          window.location.reload(); 
    }
    }); 
    }
  Confirmstatus =(confirmstatus)=>{
    console.log(this.state.selectedStatus,'confirmstatus')
    if(confirmstatus == 'yes'){
        if(this.state.selectedStatus=="Delete"){
          this.deletetab()
        }else{
          this.activatecoupons();
        }
        $('.confirm-action').removeClass('show');
    }else{
      $('.confirm-action').removeClass('show');	
    }
  }
  render() {
    const navigate = this.props.navigate;
    return (
      <div className="wrapper"> 
 
    <Header />
    <MenuSidebar currentpage="coupons" />  
      
      <div className="content"> 
        <div className="content-wrapper">
        <div class="content-wrapper-before"></div>
        <div className="car-listing-wrap leaa-admin-head card">
              <div className="listing-header">
                <div className="title">
                  <h3>{this.state.totalRecord} Coupons</h3>
                </div>

                 <div className="search-list">
                   <form className="edit" id="edit" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }} >     
                    <div className="search-inner">
                      <input type="text" name="search_all" onChange={this.handleInputChange} className="form-control" value={this.state.search_all} placeholder="Search" /> 
                     <button className="" type="submit">Submit</button>
                    <div className="erroremail"></div>
                  </div>
                 </form>
                </div>
              <div className="add_button">  
                    <a className="add" href='/coupon-add'>Add</a> 
                </div>
              </div>            
              {/*<div className="statusmessage"></div>*/}

              <div className="car-listing-row">
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>S.NO</th>
                      <th>Coupon Code</th>
					  <th>Discount Amount</th>
					  <th>Valid From</th>
					  <th>Valid Till</th>
					  <th>Total Usage</th>
					  <th>Created On</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>{this.couponlist()}</tbody>
                </Table>
                 <div className="text-center">
            <Pagination
            activePage={this.state.activePage}
            itemsCountPerPage={10}
            totalItemsCount={this.state.totalRecord}
            pageRangeDisplayed={5}
            onChange={this.handlePageChange.bind(this)}
            />
          </div>
              </div>

            </div> 
        </div>  
      </div>
      <div className="confirm-action">
          <p>Are you sure! you want to {this.state.selectedStatus} the Color?</p>
          <a href="javascript://" onClick={this.Confirmstatus.bind(this, "yes")} className="btn">Yes</a>
          <a href="javascript://" onClick={this.Confirmstatus.bind(this, "no")} className="btn">No</a>
		  </div>
    </div>
    );
  }
}




export default withRouter(List);