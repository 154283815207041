import React, { Component } from 'react';
import { Link } from "react-router-dom";

import $ from 'jquery';
import {
  Row,
  Col,
  Modal,
  Table,
  OverlayTrigger,
  ButtonToolbar,
  Tooltip,
  Button
} from "react-bootstrap";
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import Pagination from "react-js-pagination";
import { useParams, useNavigate } from 'react-router-dom';
import { scrollToTopValidate,PageTitle } from "../Helpers/SettingHelper";
import { apiUrl, carImageUrl, currency, dummycarUrl} from'../Config/Config';
import axios from 'axios';
const withRouter = WrappedComponent => props => {
	const params = useParams();
	const  navigate = useNavigate();
	// etc... other react-router-dom v6 hooks
  
	return (
	  <WrappedComponent
		{...props}
		params={params}
		navigate ={navigate}
		// etc...
	  />
	);
  };

class List extends Component {
  
  constructor(props)
  {
    super(props); 
    this.state = {
      fontlist:'',
      editable: false,
      priorityid:'',
      priorityvalue:'',
      activePage: 1,
      totalRecord: '',
      search_all:'',
      subscriptionlist:"",
      admin_id: localStorage.getItem("admin_id")
    };

     var admin_id = localStorage.getItem("admin_id");
      var qs = require('qs');
      var postobject = {
        activePage: 1,
        search_all: '',
        admin_id: admin_id,

      };

     
      axios.post(apiUrl+"subscription/list",qs.stringify(postobject)).then(res => {
        if(res.data.status === 'success'){
         
            this.setState({  subscriptionlist: res.data, totalRecord: res.data.records_count,data_status: res.data.status}, function() {
            
              this.subscriptionlist();
            });
  
        }
      }); 

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    }

   
   componentDidMount() {
      document.title = PageTitle('Subscription');
      if(localStorage.getItem('admin_id') === null){
      this.props.history.push('/');
      }
      var admin_id = localStorage.getItem("admin_id");
      var qs = require('qs');
     
   }

   handlePageChange(pageNumber) {
     // console.log(`active page is ${pageNumber}`);
      this.setState({activePage: pageNumber});
      var qs = require('qs');
      var admin_id = localStorage.getItem("admin_id");
      var postobject = {
        activePage: pageNumber,
        search_all: this.state.search_all,
        admin_id: admin_id
      };
    //this.props.getSubscriptionList(qs.stringify(postobject))
    }

    handleInputChange(event) {
    const {name, value} = event.target;      
    this.setState({
      [name]: value
    });
    
  }
   handleFormSubmit = () => {
        
          this.setState({Loading:true});
          var qs = require('qs');
          const formPayload = this.state;
          var admin_id = localStorage.getItem("admin_id");
          var postObject = {             
              activePage : 1,
              search_all : formPayload.search_all,
              admin_id  : admin_id         
          };
          //console.log(postObject);
          this.props.getSubscriptionList(qs.stringify(postObject));
        
    }

   

deletesubscription(id){
        var qs = require('qs');
        var admin_id = localStorage.getItem("admin_id");
        var postObject = {
          subscription_id  : id,
          admin_id  : admin_id
        };
      this.props.getSubscriptionDelete(qs.stringify(postObject));
}
confirmPopup = (id, status) => {
  var status = status;
  console.log(status,'statusnew')
    if(status == 'Inactive'){
      status = "Active";
    }else if(status == 'Active'){
      status = "Inactive";
    }else{
      status="Delete";
    }
  this.setState({selectid: id, selectedStatus: status})	
  $('.confirm-action').addClass('show');		
}
subscriptionlist() {
    var subscriptionlist = this.state.subscriptionlist;
    if (subscriptionlist != "undefined" && subscriptionlist != null && subscriptionlist.subscriptionlist != '') {
      if (subscriptionlist.status == "success") {
        //console.log(subscriptionlist)
        if (Object.keys(subscriptionlist).length > 0) {
          const subscriptionlistDetails = subscriptionlist.subscriptionlist.map(
            (subscriptionlist, Index) => {
              let sno = Index+1;
              if(subscriptionlist.status == 'A'){
                var status = 'Active';
              }else{
                  status = 'Inactive';
              }
              return (
                <tr key={subscriptionlist.subscription_id}>
                <td>{sno}</td>
                  <td>
                  {subscriptionlist.subscription_name}
                  </td>
                  <td>
                  {subscriptionlist.subscription_days}
                  </td>
                  <td>{status}</td>
                  <td className='actiongroup'>
                   <Link to={{ pathname: '/subscription-edit/'+subscriptionlist.subscription_id}} className="subscriptionedit" title="edit">  <i className="ft-edit-3" aria-hidden="true"></i></Link>
                   <Link onClick={this.confirmPopup.bind(this, subscriptionlist.subscription_id)} className="subscriptionedit" title="delete">  <i className="ft-trash-2" aria-hidden="true"></i></Link>

                  </td>
                </tr>
              );
            }
          );
          return subscriptionlistDetails ;
        }
      } else {
        return (
          <tr>
            <td colspan="6" className="v-align-nr">
              No Result
            </td>
          </tr>
        );
      }
    }else {
        return (
          <tr>
            <td colspan="6" className="v-align-nr">
              No List Found.
            </td>
          </tr>
        );
      }
  }
  deletetab(){
    var qs = require('qs');
    var postObject = {
      admin_id : localStorage.getItem("admin_id"),
      subscription_id  :this.state.selectid,
    };
    
    axios.post(apiUrl+"subscription/delete",qs.stringify(postObject)).then(res => {
    if(res.data.status === 'success'){  
      $('.success_message').html('<div class="status_sucess"><h3>'+ res.data.message +'</h3></div>');
        setTimeout(
          function() {
            $('.success_message').html('');
             
          }
          .bind(this),
          3000
          );     
          window.location.reload(); 
    }
    }); 
    }
  Confirmstatus =(confirmstatus)=>{
    console.log(this.state.selectedStatus,'confirmstatus')
    if(confirmstatus == 'yes'){
        if(this.state.selectedStatus=="Delete"){
          this.deletetab()
        }else{
          this.activatecolor();
        }
        $('.confirm-action').removeClass('show');
    }else{
      $('.confirm-action').removeClass('show');	
    }
  }
  render() {
    return (
      <div className="wrapper"> 
 
    <Header />
    <MenuSidebar currentpage="subscriptions" />  
      
      <div className="content"> 
        <div className="content-wrapper">
        <div class="content-wrapper-before"></div>
       
            <div className="car-listing-wrap leaa-admin-head card">
             <div className="success_message"></div>
              <div className="listing-header">
                <div className="title">
                <h3>{this.state.totalRecord} Subscriptions</h3>
                </div>

                 <div className="search-list">
                   <form className="edit" id="edit" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }} >     
                    <div className="search-inner">
                      <input type="text" name="search_all" onChange={this.handleInputChange} className="form-control" value={this.state.search_all} placeholder="Search" /> 
                     <button className="" type="submit">Submit</button>
                    <div className="erroremail"></div>
                  </div>
                 </form>
                </div>

                 {this.state.totalRecord < 6 && 
                  <div className="add_button">  
                      <a className="add" href='/subscription/add'>Add</a> 
                  </div>
                } 
                
              </div>            
              {/*<div className="statusmessage"></div>*/}

              <div className="car-listing-row">
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>S.NO</th>
                      <th>Subscription Name</th>
                      <th> Period</th>
                      <th>Status</th>

                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>{this.subscriptionlist()}</tbody>
                </Table>
                 <div className="text-center">
           <Pagination
            activePage={this.state.activePage}
            itemsCountPerPage={10}
            totalItemsCount={this.state.totalRecord}
            pageRangeDisplayed={5}
            onChange={this.handlePageChange.bind(this)}
            />
          </div>
              </div>

            </div> 
        </div>  
      </div>
      <div className="confirm-action">
          <p>Are you sure! you want to {this.state.selectedStatus} the subscription?</p>
          <a href="javascript://" onClick={this.Confirmstatus.bind(this, "yes")} className="btn">Yes</a>
          <a href="javascript://" onClick={this.Confirmstatus.bind(this, "no")} className="btn">No</a>
		  </div>  
    </div>
    );
  }
}




export default withRouter(List);