import React, { Component } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import $ from 'jquery';
import Select from 'react-select';
import { lang } from '../Helpers/lang';
import { PageTitle, CheckAuth } from "../Helpers/SettingHelper";
import { apiUrl} from'../Config/Config';
import axios from 'axios';
const withRouter = WrappedComponent => props => {
	const params = useParams();
	const  navigate = useNavigate();
	// etc... other react-router-dom v6 hooks
  
	return (
	  <WrappedComponent
		{...props}
		params={params}
		navigate ={navigate}
		// etc...
	  />
	);
  };
class Edit extends Component {
	
	constructor(props)
	{
		CheckAuth();
		super(props);	
		
	    const CompanyId = (typeof props.params.ID === 'undefined') ? '' : props.params.ID;
		this.state = {
			admin_company_name:'',
			admin_fname:'',
			admin_username:'',
			admin_contact:'',
			admin_email_address:'',
			selectedvalue: '',
            selectedOption:{ value: 'active', label: 'Active'},
			statusmessage:'',
			user_password:'',
			user_confirm_password:'',
			c_company_id: CompanyId,
			Loading:false,
			domain_count:''
		};
		var qs = require('qs');
		var companyId = this.state.c_company_id;

		axios.get(apiUrl+"company/listdetail?id="+companyId).then(res => {
			if(res.data.status == 'success'){
				const formdata = res.data.companylist;
			if(formdata.admin_status === 'Yes'){
				this.setState({selectedOption:{value: 'active', label: 'Active'}});
			}else{
				this.setState({selectedOption:{value: 'inactive', label: 'Inactive'}});

			}
			this.setState({admin_company_name:formdata.admin_company_name});						
			//this.setState({c_company_id: formdata.c_company_id});						
			this.setState({admin_contact: formdata.admin_contact});
			this.setState({admin_email_address: formdata.admin_email_address});
			this.setState({admin_username: formdata.admin_username});
			this.setState({domain_count: formdata.domain_count});
			}
			
		}); 

		this.handleInputChange = this.handleInputChange.bind(this);
	    this.handleChange = this.handleChange.bind(this);
		this.new_pass_show_hide = this.new_pass_show_hide.bind(this);
        this.confim_pass_show_hide = this.confim_pass_show_hide.bind(this);

    }
	

    handleChange = selectedOption => {
        this.setState({selectedOption})
        this.setState({  selectedvalue : selectedOption.value});
    };
   handleInputChange(event) {

      const {name, value} = event.target;      
      this.setState({
          [name]: value
        });
    }
	handleChangeTxt = (item, event) => {  
        this.setState({[item]:event.target.value }); 
    }
	new_pass_show_hide = () => {
		this.setState({
			 new_pass_type: this.state.new_pass_type === 'input' ? 'password' : 'input',
			 pass_show_hide_icon: this.state.new_pass_type === 'input' ? 'shown' : 'hiden'
			});
	}
		
	confim_pass_show_hide = () => {
		this.setState({
			 confrim_pass_type: this.state.confrim_pass_type === 'input' ? 'password' : 'input',
			 confirm_show_hide_icon: this.state.confrim_pass_type === 'input' ? 'shown' : 'hiden'
			});
	}   

	componentDidMount() {
		document.title = PageTitle('Company Edit');
    }

        handleFormSubmit = () => {
			if(this.validateForm()){ 
				this.setState({Loading:true});
				const formPayload = this.state;
				var qs = require('qs');
				var status = '';
				if(formPayload.selectedvalue === '' && formPayload.selectedvalue!== 0){
				status =formPayload.selectedOption.value;
				}else{
				status = formPayload.selectedvalue;
				}
				var postObject = {
					admin_id : localStorage.getItem("admin_id"),
					c_company_id : formPayload.c_company_id,
					admin_company_name: formPayload.admin_company_name,					
					admin_username:formPayload.admin_username,
					admin_contact:formPayload.admin_contact,
					admin_email_address:formPayload.admin_email_address,	
					password:formPayload.user_password,
					domain_count: formPayload.domain_count,
					admin_status:status,
				};
				//console.log(postObject);

				axios.post(apiUrl+"company/edit",qs.stringify(postObject)).then(res => {
					
					if(res.data.status === "success"){
						this.setState({ Loading: false });

						const formpayload = res.data.companylist;
						/* if(formpayload.admin_status === 'Yes'){
							this.setState({selectedOption:{value: 'active', label: 'Active'}});
						}else{
						this.setState({selectedOption:{value: 'inactive', label: 'Inactive'}});

						}
						this.setState({admin_company_name:formpayload.admin_company_name});
						
						//this.setState({c_company_id: formpayload.c_company_id});
						this.setState({admin_contact: formpayload.admin_contact});
						this.setState({admin_email_address: formpayload.admin_email_address});
						this.setState({admin_username: formpayload.admin_username}); */
						$('.success_message').html('<div class="status_sucess"><h3>'+ res.data.message+'</h3></div>');


								setTimeout(
								function() {
									$('.success_message').html('');
									this.props.navigate('/company');
								}
								.bind(this),
								3000
								);
					} else {
						this.setState({ Loading: false });
						$('.success_message').html('<div class="status_sucess"><h3>'+ res.data.message+'</h3></div>');
							setTimeout(
							function() {
								$('.success_message').html('');
							}
							.bind(this),
							3000
							);
					}
				});
			}
	}

	validateForm() {
		//const {admin_company_name,admin_fname} = this.state;
		const {domain_count,admin_company_name,admin_fname,admin_username,admin_email_address,admin_contact,user_password,user_confirm_password} = this.state;
		let errors = {};
      	let formIsValid = true;
	
		  var maxCharacterLimit =20;
		  if (!admin_company_name) {
			  formIsValid = false;
			  $('.erroradmin_company_name').html('<span class="errorspan">Please fill the field</span>');
		  }else if(admin_company_name){
			  if (admin_company_name.length <= maxCharacterLimit) {
				  $('.erroradmin_company_name').html('');
	  
				}else{
				  $('.erroradmin_company_name').html('Company name should be less than 20 characters');
	  
				}
			  
		  }
		  
		  if (!admin_username) {
			  formIsValid = false;
			  $('.erroradmin_username').html('<span class="errorspan">Please fill the field</span>');
		  }else if(admin_username){
			  if (admin_username.length <= maxCharacterLimit) {
				  $('.erroradmin_username').html('');
	  
				}else{
				  $('.erroradmin_username').html('Username should be less than 20 characters');
	  
				}
			  
		  }		
		  
		  if (!admin_email_address) {
			  formIsValid = false;
			  
			  $('.erroradmin_email_address').html('<span class="errorspan">Email is required</span>');
			  }else if(admin_email_address){
				
			  var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
			  if (reg.test(admin_email_address)) 
			  {
				
				$('.erroradmin_email_address').html('');
			  }else{
				
				  formIsValid = false;
				
			  $('.erroradmin_email_address').html('<span class="errorspan">Please enter valid email address</span>');
			  }
				
			  
			  }
		  
  
		  if (!admin_contact) {
			  formIsValid = false;
			  
			  $('.erroradmin_contact').html('<span class="errorspan">Contact is required</span>');
			}else if(admin_contact){	
			   var pattern = /^(\([0-9]{3}\)|[0-9]{3}-)[0-9]{3}-[0-9]{4}$/;
			   var indiapattern =/^([0|\+[0-9]{1,5})?([7-9][0-9]{9})$/;
				  if (pattern.test(admin_contact)) {
				  $('.erroradmin_contact').html('');
				  }else if(indiapattern.test(admin_contact)){
				  $('.erroradmin_contact').html('');
				  }else{
					  formIsValid = false;
				  
					   $('.erroradmin_contact').html('<span class="errorspan">Please enter valid contact</span>');
					 }
				  }
		  
  
		  if (!domain_count) {
			  formIsValid = false;
			  $('.errordomain_count').html('<span class="errorspan">Please fill the field</span>');
		  }else if(domain_count){
			  $('.errordomain_count').html('');
		  }
  
  
        if(user_password !== user_confirm_password){
            formIsValid = false;
             $('.usercpswd').html('<span class="errorspan">Password does not match</span>');
        }

		this.setState({
			errors: errors
		});
		return formIsValid;
    }

	
    componentWillReceiveProps(Props){
    	if(Props.companyedit !== this.props.companyedit){
    		if(Object.keys(Props.companyedit).length > 0){
				if(Props.companyedit[0].status === "success"){
    					this.setState({ Loading: false });

    			const formpayload = Props.companyedit[0].companylist;
				if(formpayload.admin_status === 'Yes'){
					this.setState({selectedOption:{value: 'active', label: 'Active'}});
				}else{
				this.setState({selectedOption:{value: 'inactive', label: 'Inactive'}});

				}
				this.setState({admin_company_name:formpayload.admin_company_name});
				
				//this.setState({c_company_id: formpayload.c_company_id});
				this.setState({admin_contact: formpayload.admin_contact});
				this.setState({admin_email_address: formpayload.admin_email_address});
				this.setState({admin_username: formpayload.admin_username});
               	$('.success_message').html('<div class="status_sucess"><h3>'+ Props.companyedit[0].message+'</h3></div>');


    					setTimeout(
						function() {
							$('.success_message').html(this.props.history.push('/company'));
						}
						.bind(this),
						3000
						);
    		}else{
					this.setState({ Loading: false });
					$('.success_message').html('<div class="status_sucess"><h3>'+ Props.companyedit[0].message+'</h3></div>');
    					setTimeout(
						function() {
							$('.success_message').html('');
						}
						.bind(this),
						3000
						);
			}
			}
    	}
		
    }
	
  render() {
  	  const {selectedOption} = this.state;
		const navigate = this.props.navigate;
    return (
      <div className="wrapper"> 
 
	  <Header />
	  <MenuSidebar currentpage="companylist" />    
	

	
	<div className="content">	
		<div className="content-wrapper">
			<div className="">
				
			<div class="content-wrapper-before"></div>
			<div className="car-listing-wrap leaa-admin-head card">
				<div className="success_message"></div>
					<div className="back-btn-lux">
						<a onClick={() => navigate(-1)}>Back</a>   
					</div>
					<div className="title">
						<h4>Edit Details</h4>
					</div>
					<form className="edit" id="edit" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }}>
					<div className="form-row">
					<div className="form-left">
						<div className="form-group">
							<label>Company Name<span class="required" style={{ color: "red" }} >* </span></label>
							<input type="text" name="admin_company_name" onChange={this.handleInputChange} className="form-control" value={this.state.admin_company_name} />
							<div className="erroradmin_company_name"></div>
						</div>
						
						
						<div className="form-group">					
							<label>Contact<span class="required" style={{ color: "red" }} >* </span></label>
								<input type="text" name="admin_contact" onChange={this.handleInputChange} className="form-control" value={this.state.admin_contact} />
							<div className="erroradmin_contact"></div>
						</div>
						<div className="form-group eye-pad">
                            <label>Reset Password</label>
                            <input type={this.state.new_pass_type}  name="user_password" placeholder="Enter new password" className="form-control" value={this.state.user_password} onChange={this.handleChangeTxt.bind(this, 'user_password')}/>
                            <span toggle="#password-field" className={'shown ' + this.state.pass_show_hide_icon} onClick={this.new_pass_show_hide}><i className="ft-eye" aria-hidden="true"></i></span>
                            <div className="userpswd"></div>
                        </div>
                        <div className="form-group eye-pad">
                            <label>Confirm Password</label>
                            <input type={this.state.confrim_pass_type} name="user_confirm_password" id="user_confirm_password" placeholder="Enter confirm password" className="form-control" value={this.state.user_confirm_password} onChange={this.handleChangeTxt.bind(this, 'user_confirm_password')}/>
                            <span toggle="#password-field" className={'shown ' + this.state.confirm_show_hide_icon} onClick={this.confim_pass_show_hide}><i className="ft-eye" aria-hidden="true"></i></span>
                            <div className="usercpswd"></div>
                        </div>
					</div>	

					<div className="form-right">
						<div className="form-group">
							<label>User Name<span class="required" style={{ color: "red" }} >* </span></label>
							<input type="text" name="admin_username" onChange={this.handleInputChange} className="form-control" value={this.state.admin_username} />
							<div className="erroradmin_username"></div>
						</div>
						<div className="form-group">
							<label>Email<span class="required" style={{ color: "red" }} >* </span></label>
								<input type="text" name="admin_email_address" onChange={this.handleInputChange} className="form-control" value={this.state.admin_email_address} />
							<div className="erroradmin_email_address"></div>
						</div>

						<div className="form-group">					
						<label>Domain Restriction Count<span class="required" style={{ color: "red" }} >* </span></label>
						<input type="text" name="domain_count" onChange={this.handleInputChange} className="form-control" value={this.state.domain_count} />
						<div className="errordomain_count"></div>
						</div>
						<div className="form-group">					
							<label>Status<span class="required" style={{ color: "red" }} >* </span></label>
								<Select 
								value={selectedOption?selectedOption:{ value: 'active', label: 'Active'}}
									options={lang.common.status_option} 
									onChange={this.handleChange}
									/>
							<div className="erroradmin_email_address"></div>
						</div>
					</div>
					</div>		

					<div className="btn-group export">	
						<button className="btn btn_orange btn_minwid login_submit animate-btn2" type="submit" disabled={(this.state.Loading ===true)?true:false}> {this.state.Loading ===true &&
												<span className="load-data"></span> 
												}Submit
						</button>
					</div>
					</form>
				</div>	
			</div>			
		</div>	
	</div>
		 	
    </div>
    );
  }
}

export default withRouter(Edit);