import React, { Component } from 'react';
import { Link, withRouter } from "react-router-dom";
import Pagination from "react-js-pagination";
import { apiUrl} from'../Config/Config';
import axios from 'axios';
import $ from 'jquery';
import { lang } from '../Helpers/lang';

import {
  Row,
  Col,
  Modal,
  Table,
  OverlayTrigger,
  ButtonToolbar,
  Tooltip,
  Button
} from "react-bootstrap";
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import { baseUrl } from '../Config/Config';

class List extends Component {
  
  constructor(props)
  {
     super(props); 
      this.state = {
        formlist:'',
          activePage: 1,
          totalRecord: '',
          search_all:'',
          selectedStatus:'',
          selectid:'',
      };
    

      this.handleInputChange = this.handleInputChange.bind(this);
      this.handleFormSubmit = this.handleFormSubmit.bind(this);

    }

   
   componentDidMount() {
   this.listloadedData();
   }
   listloadedData(postobject=''){ 
    var qs = require('qs');
    if(postobject!=''){
      var postobject = postobject
    }else{
     
      var postobject = {
      activePage: 1,
      search_all: '',
      admin_id: localStorage.getItem('admin_id')  
      };
    }
 
   axios.post(apiUrl+"formbuilder/list",qs.stringify(postobject)).then(res => {
     
     if(res.data.status === 'success'){
         this.setState({  formlist: res.data, totalRecord: res.data.records_count}, function() {
           this.formlist();
         });
     }
   }); 
}

  handlePageChange(pageNumber) {
   
    this.setState({activePage: pageNumber});
    var qs = require('qs');
    var postobject = {
      activePage: pageNumber,
      search_all: this.state.search_all,
      admin_id: localStorage.getItem('admin_id')  
    };
    this.listloadedData(postobject)
  }

  handleInputChange(event) {
  const {name, value} = event.target; 
  var postObject = {             
    activePage   : 1,
    search_all : value,
    admin_id: localStorage.getItem('admin_id')           
  };     
  this.setState({
    [name]: value
  },function(){
    this.listloadedData(postObject)
  });
  
}
 handleFormSubmit = () => {
      
        this.setState({Loading:true});
        var qs = require('qs');
        const formPayload = this.state;
         
        var postObject = {             
          activePage   : 1,
          search_all : formPayload.search_all,
          admin_id: localStorage.getItem('admin_id')           
        };
        this.listloadedData(postObject)
      
  } 

   componentWillReceiveProps(Props){
   }

// deleteuser(id){
//         var qs = require('qs');
//         var postObject = {
//           admin_id : localStorage.getItem("admin_id"),
//           rehab_id  :id
//         };
//         axios.post(apiUrl+"rehab/list",qs.stringify(postObject)).then(res => {      
//           if(res.data.status === 'success'){
//               this.setState({  rehablist: res.data, totalRecord: res.data.records_count}, function() {
//                 this.rehablist();
//               });
//           }
//         }); 
// }



activaterehab(){
  
	let stat = ''
		if(this.state.selectedStatus ==='inactive'){
			stat = "I";
		}else{
			stat = "A";
		}  

        var qs = require('qs');
        var postObject = {
          admin_id : localStorage.getItem("admin_id"),
          id  :this.state.selectid,
		     status: stat
        };
        axios.post(apiUrl+"formbuilder/change_status",qs.stringify(postObject)).then(res => {      
          if(res.data.status === 'success'){
              this.listloadedData()
              $('.success_message').html('<div class="status_sucess"><h3> '+ res.data.message +' </h3></div>');
              setTimeout(
                function() {
               
                  $('.success_message').html('');
                   
                }
                .bind(this),
                3000
                );
                
          }
        }); 
}

confirmPopup = (id, status) => {
  var status = status;
  console.log(status,'statusnewssssssssssss')
  if(status == 'Inactive'){
    status = "active";
  }else if(status == 'Active'){
    status = "inactive";
  } else{
    status="Delete";
  }
  this.setState({selectid: id, selectedStatus: status})	
  $('.confirm-action').addClass('show');		
}

Confirmstatus = (confirmstatus) => {
  if(confirmstatus == 'yes'){
    this.activaterehab();
    $('.confirm-action').removeClass('show');
  }else{
    $('.confirm-action').removeClass('show');	
  }
    
}
exportdata(){
	let stat = '';		
        var qs = require('qs');
        var postObject = {
          admin_id : localStorage.getItem("admin_id")
        };
        axios.post(apiUrl+"Rehab/export",qs.stringify(postObject)).then(res => {      
          if(res.data.status === 'success'){
            var url = res.data.url;
            var cleanurl = url.replace(/([^:]\/)\/+/g, "$1");
            window.open(cleanurl, '_blank', 'noreferrer');
            // window.location.reload();
          }
        }); 
      
}
activateuser(id, status){
  let stat = ''
    if(status == 'Inactive'){
      stat = "A";
    }else{
      stat = "I";
    }
        var qs = require('qs');
        var postObject = {
          admin_id : localStorage.getItem("admin_id"),
          id  :id,
          status: stat
        };
      this.props.getActivatePackage(qs.stringify(postObject));
}
formlist() {

  var formlist = this.state.formlist;
  if (formlist != "undefined" && formlist != null) {

    if (formlist.status == "success") {

      if (Object.keys(formlist).length > 0) {
        const vehiclelistDetails = formlist.formlist.map(
          (formlist, Index) => {
            let sno = Index+1;
            if(formlist.status == 'A'){
              var status = 'Active';
            }else{
                status = 'Inactive';
            }
             if(formlist. form_column == 1){
              var column = 'One Column';
            }else{
                column = 'Two Column';
            }


           var istyle = { width: 100};
            return (
              <tr key={formlist.id}>
                  <td>{sno}</td>
                  <td>{formlist.form_name}</td>
                  <td>{column}</td>
                  <td>{status}</td>
                   {localStorage.getItem('loggedas') === 'admin' &&
                    <> 
                  <td className="actiongroup">
                    <Link to={{ pathname: '/formbuilder/edit/'+formlist.id}} className="" title="Duplicate">  <i className="fa fa-edit" aria-hidden="true"></i></Link>
                     {(status == 'Active'?<Link onClick={this.confirmPopup.bind(this, formlist.id, status)} className="" title="Status"><i className="fa fa-unlock" aria-hidden="true"></i></Link>:<Link onClick={this.confirmPopup.bind(this, formlist.id, status)} className="" title="Status"><i className="fa fa-lock" aria-hidden="true"></i></Link>)}     
                  </td>
                  </>}
              </tr>
            );
          }
        );
        return vehiclelistDetails;
      }
    } else {

      return (
        <tr>
          <td colspan="6" className="v-align-nr">
            No Result
          </td>
        </tr>
      );

    }
  }

}
  
  render() {
    return (
      <div className="wrapper"> 
       <div className="confirm-action">
			<p>Are you sure! you want to {this.state.selectedStatus} the Form?</p>
			<a href="javascript://" onClick={this.Confirmstatus.bind(this, "yes")} className="btn">Yes</a>
			<a href="javascript://" onClick={this.Confirmstatus.bind(this, "no")} className="btn">No</a>
		</div>
    <Header />
    <MenuSidebar currentpage="formbuilder" />  
      
      <div className="content"> 
        <div className="content-wrapper">
            <div className="car-listing-wrap">

              <div className="listing-header">
                <div className="title">
                  <h3>{this.state.totalRecord} Forms</h3>
                  
                </div>

                <div className="search-list">
                   <form className="edit" id="edit" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }} >     
                    <div className="search-inner">
                      <input type="text" name="search_all" onChange={this.handleInputChange} className="form-control" value={this.state.search_all} placeholder="Search" /> 
                     <button className="" type="submit">Submit</button>
                    <div className="erroremail"></div>
                  </div>
                 </form>
                </div> 
                  <div className="add_button">  
                      <a className="add" href='/form-add'>Add</a> 
                  </div>
              </div>            
              {/*<div className="statusmessage"></div>*/}

              <div className="car-listing-row">
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>S.NO</th>
                      <th>Form Name</th>
                      <th>Form Layout</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>{this.formlist()}</tbody>
                </Table>
                <div className="text-center">
                  <Pagination
                  activePage={this.state.activePage}
                  itemsCountPerPage={10}
                  totalItemsCount={this.state.totalRecord}
                  pageRangeDisplayed={5}
                  onChange={this.handlePageChange.bind(this)}
                  />
                </div>
              </div>

            </div> 
        </div>  
      </div>
      
    </div>
    );
  }
}

export default List;