import React, { Component } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Multiselect from 'multiselect-react-dropdown';

import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import $ from 'jquery';
import Select from 'react-select';
import { lang } from '../Helpers/lang';
import { PageTitle, scrollToTopValidate, scrollToTop } from "../Helpers/SettingHelper";
import { carImageUrl } from '../Config/Config';
import { apiUrl} from'../Config/Config';
import axios from 'axios';
import { CKEditor, CKEditorContext } from '@ckeditor/ckeditor5-react';
import MyEditor from '../Membership/MyEditor';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
/* 
import {GET_EDITUSER,GET_USERDETAILLIST  }  from '../../actions';  */
const withRouter = WrappedComponent => props => {
	const params = useParams();
	const  navigate = useNavigate();
	// etc... other react-router-dom v6 hooks
  
	return (
	  <WrappedComponent
		{...props}
		params={params}
		navigate ={navigate}
		// etc...
	  />
	);
  };
class Edit extends Component {
	
	fileObj = [];
    fileArray = [];
    imageArray = [];
	
	constructor(props)
	{
		super(props);	
	    const packageid = (typeof props.params.ID === 'undefined') ? '' : props.params.ID;

		this.state = {
			packageid: packageid,
			username:'',
			firstname:'',
			lastname:'',
			email:'',
			phoneno:'',
			password:'',
			status:'',
			changepassword:'',
			user_confirm_password: '',
			 new_pass_type: 'password',
			confrim_pass_type: 'password',
            pass_show_hide_icon: 'shown',
            confirm_show_hide_icon: 'shown',
            selectedOption:{ value: 'active', label: 'Active'},
			selectedvalue:'',
			disablepasssword:true,
			selectedDomainOption:'',
			selectedDomainvalue:'',
			maxCharacterLimit : 10,
			selectedYear:'',
			selectedYearvalue:'',
			street:'',
			city:'',
			state:'',
			country:'',
			postalcode:'',
			amenitynamelist:[],
			selectedcategoryvalue:'',
			selectedamenityid:'',
			package_content:''
		};
		this.onEditorChange = this.onEditorChange.bind(this);
		var qs = require('qs');
		axios.get(apiUrl+"package/packagedetail?id="+packageid).then(res => {
			if(res.data.status == 'success'){
				const formdata = res.data.packagelist;
						if(formdata.status === 'A'){
							this.setState({selectedOption:{value: 'active', label: 'Active'}});
						}else{
							this.setState({selectedOption:{value: 'inactive', label: 'Inactive'}});

						}
						this.setState({type:formdata.type});
						this.setState({price:formdata.price});
						var callfunc = this;
						setTimeout(function () {
						callfunc.setState({package_content:formdata.package_content});
						}, 1000);

						//this.setState({logo_content:formdata.landing_content});
						this.setState({package_id: formdata.id});
			}
			
		}); 
		
	   this.handleInputChange = this.handleInputChange.bind(this);
	   this.new_pass_show_hide = this.new_pass_show_hide.bind(this);
       this.confim_pass_show_hide = this.confim_pass_show_hide.bind(this);
	   this.handleChange = this.handleChange.bind(this);
	   
    }
	isUSZipCode(zipCode) {
		// Regular expression pattern for validating US ZIP codes
		const zipCodePattern = /^\d{5}(-\d{4})?$/;
		return zipCodePattern.test(zipCode);
	  }
   handleInputChange(event) {

      const {name, value} = event.target;      
      this.setState({
          [name]: value
        });
     
    }
	
     handleChange = selectedOption => {
        this.setState({selectedOption})
        this.setState({  selectedvalue : selectedOption.value});
    };
	componentDidMount() {
		document.title = PageTitle('user Edit');
		if(localStorage.getItem('admin_id') === null){
		this.props.history.push('/');
		}
		
    }
	onEditorChange( evt ) {
    	console.log(evt.editor.getData())
    	var callfunc = this;
    	setTimeout(function () {
		callfunc.setState( {
            package_content: evt.editor.getData()
        } );
		}, 1000);
     
    }
	new_pass_show_hide = () => {
    this.setState({
         new_pass_type: this.state.new_pass_type === 'input' ? 'password' : 'input',
         pass_show_hide_icon: this.state.new_pass_type === 'input' ? 'shown' : 'hiden'
        });
    }
    
    confim_pass_show_hide = () => {
    this.setState({
         confrim_pass_type: this.state.confrim_pass_type === 'input' ? 'password' : 'input',
         confirm_show_hide_icon: this.state.confrim_pass_type === 'input' ? 'shown' : 'hiden'
        });
    }
	
	
 handleFormSubmit = () => {
			if(this.validateForm()){
				this.setState({Loading:true});
				 const config = {
			        headers: {
			          'Content-Type': 'multipart/form-data'
			        }
			      };

				const formPayload = this.state;
				var qs = require('qs');
				var status = '';
				if(formPayload.selectedvalue === '' && formPayload.selectedvalue!== 0){
				status =formPayload.selectedOption.value;
				}else{
				status = formPayload.selectedvalue;
				}
			
				var postObject = {
					admin_id : localStorage.getItem("admin_id"),
					id : formPayload.package_id,
					type: formPayload.type,
					package_content:formPayload.package_content,
					price   : formPayload.price,
					status      :status,
					
				};

				let formData = new FormData();
				for(let k in postObject) {
				formData.append(k, postObject[k]);
				}
				
				axios.post(apiUrl + "package/edit", formData, {
					headers: {
						'Content-Type': 'multipart/form-data'
					}
				}).then(res => {
					if(res.data.status === "success"){
						this.setState({ Loading: false });

						const formpayload = res.data.rehablist;
						
						$('.success_message').html('<div class="status_sucess"><h3>'+ res.data.message+'</h3></div>');


						setTimeout(
							function() {
								this.props.navigate('/packages');
							
							}
							.bind(this),
							3000
							);
					} else {
						
						this.setState({ Loading: false });
						$('.success_message').html('<div class="status_sucess"><h3>'+ res.data.message+'</h3></div>');
							setTimeout(
							function() {
								$('.success_message').html('');
							}
							.bind(this),
							3000
							);
					}
				});
			}
	}

	
		validateForm() {
			const {type,package_content,price} = this.state;
			let errors = 0;
		
			if (!type) {
				errors++;
				$('.errorname').html('<span class="errorspan">Please fill the field</span>');
			}else if(type){
				$('.errorname').html('');
			}
	
			if (!price) {
				errors++;
				$('.errorprice').html('<span class="errorspan">Please fill the field</span>');
			}else if(price){
				$('.errorprice').html('');
			}
	
			if(errors>0) {
			setTimeout(function () {
			scrollToTopValidate();
			}, 100);
			return false;
			}
			else {
			return true;
			}
	
		}
    

    
	  handledomainChange = selectedDomainOption => {
		
		this.setState({selectedDomainOption:selectedDomainOption})
		this.setState({  selectedDomainvalue : selectedDomainOption.value});
	
}

handleYearChange = selOption => {
	this.setState({selectedYear:selOption})
		this.setState({  selectedYearvalue : selOption.value});
};
handleProductCategoryChange = (event) => {  
	console.log(event,'onchange')
	this.setState({selectedamenityid : event}, function() {
	   const user_list = this.state.selectedamenityid;
	});
}
  render() {
	const navigate = this.props.navigate;
  	const {selectedOption,selectedDomainOption,selectedYear,amenitynamelist,selectedcategoryvalue} = this.state;

	  return (
		<div className="wrapper"> 
   
		<Header />
	  <MenuSidebar currentpage="packages" />  
  
	  
	  <div className="content">	
		  <div className="content-wrapper">
		  <div class="content-wrapper-before"></div>
			  <div className="form-wrapper leaa-admin-head card">
			  	<div className="back-btn-lux">
                  	<a onClick={() => navigate(-1)}>Back</a>    
            	</div>
			  <div className="success_message"></div>
			  <div className="title">
				  <h4>Edit Details</h4>
				  
			  </div>
			   <form className="edit" id="edit" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }} encType="multipart/form-data" >
			  <div className="form-row">
			  <div className="form-left">
				  <div className="form-group">
					  <label> Type:</label>
					  <input type="text" name="type" onChange={this.handleInputChange} className="form-control" value={this.state.type} />
					  <div className="errorname"></div>
				  </div>
  
				  <div className="form-group">					
						<label>Package Description:</label>
						<CKEditor
										editor={ ClassicEditor }
										data={this.state.package_content}
										onReady={ editor => {
											// You can store the "editor" and use when it is needed.
											console.log( 'Editor is ready to use!', editor );
										} }
										onChange={ ( event, editor ) => {
											const data = editor.getData();
											this.setState({package_content: data});
											console.log( data,"data");
										} }
									/>
					 
						<div className="errorpackage_content"></div>
					</div>
			  </div>
			  <div className="form-right">
				  
				  <div className="form-group">
					  <label> Price:</label>
					  <input type="text" name="price" onChange={this.handleInputChange} className="form-control" value={this.state.price} />
					  <div className="errorprice"></div>
				  </div>
				  
  
				  <div className="form-group">					
					  <label>Status:</label>
							 <Select 
							 value={selectedOption?selectedOption:{ value: 'active', label: 'Active'}}
							  options={lang.common.status_option} 
							  onChange={this.handleChange}
							  />
				  </div>
				  </div>
			  </div>		
  
			  <div className="btn-group export">	
					<button className="btn btn_orange btn_minwid login_submit animate-btn2" type="submit" disabled={(this.state.Loading ===true)?true:false}> {this.state.Loading ===true &&
										  <span className="load-data"></span> 
										  }Submit
					 </button>
			  </div>
			  </form>
			  </div>
				  
		  </div>	
	  </div>
			   
	  </div>
	  );
  }
}



export default withRouter(Edit);