import React, { Component } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { PageTitle, CheckAuth, scrollToTopValidate, scrollToTop } from "../Helpers/SettingHelper";
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import $ from 'jquery';
import { apiUrl} from'../Config/Config';
import axios from 'axios';
import Select from 'react-select';
import { lang } from '../Helpers/lang';

const withRouter = WrappedComponent => props => {
	const params = useParams();
	const  navigate = useNavigate();
	// etc... other react-router-dom v6 hooks
  
	return (
	  <WrappedComponent
		{...props}
		params={params}
		navigate ={navigate}
		// etc...
	  />
	);
  };
class Add extends Component {	
	constructor(props)
	{
		CheckAuth();
		super(props);	
		this.state = {
			form_name:'',
			form_one_column:true,
			form_two_column:false,
			form_field_name:'',
			form_input_type:'',
			form_input_type_value:'',
			field_priorty:'',
			form_field_name_arr:[],
			form_input_type_arr:[],
			form_input_type_value_arr:[],
			field_priorty_arr:[],
			field_required_checked:true,
			field_required:'',
			field_required_arr:[],
			showlayout: false,
			select_options:'',
			select_options_arr:[],
			selectedvalue: '',
			selectedOption:{ value: 'active', label: 'Active'},
			Loading:false,
		};
		this.deletesinglefield = this.deletesinglefield.bind(this);
		

    }
	
	deletesinglefield(Index){
		let fieldname = [...this.state.form_field_name_arr];
		let fieldtype = [...this.state.form_input_type_value_arr];
		let fieldrequired = [...this.state.field_required_arr];
		let fieldpriorty = [...this.state.field_priorty_arr]; 
		fieldname.splice(Index, 1);
		fieldtype.splice(Index, 1);
		fieldrequired.splice(Index, 1);
		fieldpriorty.splice(Index, 1);
		this.setState({form_field_name_arr: fieldname,form_input_type_value_arr: fieldtype,field_required_arr:fieldrequired,field_priorty_arr:fieldpriorty});
  
	}
	getformlayout(){
		var form_field_value = this.state.form_field_name_arr;
		if (Object.keys(form_field_value).length > 0) {
		const formlayout = form_field_value.map(
		(datas, Index) => {
		let indexdata = Index+1;
		return (
			  <div key={Index+'list'}>
			  <li className="sortablecontainer-module-list">
			  <i class="fa fa-chevron-right" aria-hidden="true"></i>
			  {datas}
			  <div className="sortablecontainer-module-edit-button">
			  {/*<button>Edit</button>*/}
			  <button onClick={this.deletesinglefield.bind(this,Index)} className='ft-trash-2 delete-btn-admin'>Delete</button>
			  </div>
			  </li></div> );
  
		}
		);
		return formlayout;
		}
	}
    handleChange = selectedOption => {
        this.setState({selectedOption})
        this.setState({  selectedvalue : selectedOption.value});
    };
	

	componentDidMount() {
		document.title = PageTitle('Company Add');
    }


        handleFormSubmit = () => {
			if(this.validateForm()){
				this.setState({Loading:true});
				const formPayload = this.state;
				var qs = require('qs');
				var status = '';
				if(formPayload.selectedvalue === '' && formPayload.selectedvalue!== 0){
				status =formPayload.selectedOption.value;
				}else{
				status = formPayload.selectedvalue;
				}
				var postObject = {
					admin_id : localStorage.getItem("admin_id"),
					admin_company_name: formPayload.admin_company_name,					
					admin_username:formPayload.admin_username,
					admin_contact:formPayload.admin_contact,
					admin_email_address:formPayload.admin_email_address,
					domain_count:formPayload.domain_count,
					admin_status:status,
				};
			   axios.post(apiUrl+"company/add",qs.stringify(postObject)).then(res => {
				 
				 if(res.data.status === 'success'){
					$('.success_message').html('<div class="status_sucess"><h3>'+ res.data.message+'</h3></div>');
						this.setState({
							admin_company_name:'',			
							admin_fname:'',
							admin_username:'',
							admin_contact:'',
							admin_email_address:'',
							selectedvalue: '',
							domain_count:''
						});
						setTimeout(
						function() {
							$('.success_message').html(this.props.navigate('/company'));
						}
						.bind(this),
						3000
						);
					}else{
						this.setState({Loading:false});
						$('.success_message').html('<div class="status_sucess"><h3>'+ res.data.message+'</h3></div>');
						
					}
			   }); 
			}
	}
afterSuccess(){
	$('.success_message').html('');
	this.props.navigate('/company');
	
}
	validateForm() {
		const {admin_company_name,admin_username,admin_email_address,admin_contact,domain_count} = this.state;
		let errors = {};
      	let formIsValid = true;
	var maxCharacterLimit =20;
		if (!admin_company_name) {
			formIsValid = false;
			$('.erroradmin_company_name').html('<span class="errorspan">Please fill the field</span>');
		}else if(admin_company_name){
			if (admin_company_name.length <= maxCharacterLimit) {
				$('.erroradmin_company_name').html('');
	
			  }else{
				$('.erroradmin_company_name').html('Company name should be less than 20 characters');
	
			  }
			
		}
		
		if (!admin_username) {
			formIsValid = false;
			$('.erroradmin_username').html('<span class="errorspan">Please fill the field</span>');
		}else if(admin_username){
			if (admin_username.length <= maxCharacterLimit) {
				$('.erroradmin_username').html('');
	
			  }else{
				$('.erroradmin_username').html('Username should be less than 20 characters');
	
			  }
			
		}		
		
		if (!admin_email_address) {
			formIsValid = false;
			
			$('.erroradmin_email_address').html('<span class="errorspan">Email is required</span>');
			}else if(admin_email_address){
			var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
			if (reg.test(admin_email_address)) 
			{
			  $('.erroradmin_email_address').html('');
			}else{
				formIsValid = false;
			  
			$('.erroradmin_email_address').html('<span class="errorspan">Please enter valid email address</span>');
			}
			  
			
			}
		

		if (!admin_contact) {
			formIsValid = false;
			
			$('.erroradmin_contact').html('<span class="errorspan">Contact is required</span>');
		  }else if(admin_contact){	
			 var pattern = /^(\([0-9]{3}\)|[0-9]{3}-)[0-9]{3}-[0-9]{4}$/;
			 var indiapattern =/^([0|\+[0-9]{1,5})?([7-9][0-9]{9})$/;
				if (pattern.test(admin_contact)) {
				$('.erroradmin_contact').html('');
				}else if(indiapattern.test(admin_contact)){
				$('.erroradmin_contact').html('');
				}else{
					formIsValid = false;
				
					 $('.erroradmin_contact').html('<span class="errorspan">Please enter valid contact</span>');
				   }
				}
		

		if (!domain_count) {
			formIsValid = false;
			$('.errordomain_count').html('<span class="errorspan">Please fill the field</span>');
		}else if(domain_count){
			$('.errordomain_count').html('');
		}



		this.setState({
			errors: errors
		});
		return formIsValid;
    }
	handlefieldTypeChange(event) {   
		this.setState({form_input_type:[event],form_input_type_value:event.value})
	}
	handleInputChange = (event) =>{
		const { name,value } = event.target;
		this.setState({[name]: value})
		 if(name === 'form_column'){
		   this.setState({form_one_column: !this.state.form_one_column});
		   this.setState({form_two_column: !this.state.form_two_column});
		}
		if(name === 'field_required'){
			this.setState({field_required_checked: !this.state.field_required_checked});
		}
		if(name == 'select_options'){
			this.setState({select_options:value})
		}
	  }
	  Addtolayout=()=>{
		if(this.validateFormfield()){
			  var valuerequired;
			  if(this.state.field_required_checked == true){
			  valuerequired = 'yes';
			  }else{
			  valuerequired = 'no';
			  }
			  this.setState({
			  form_field_name_arr: this.state.form_field_name_arr.concat(this.state.form_field_name), form_input_type_value_arr: this.state.form_input_type_value_arr.concat(this.state.form_input_type_value),field_priorty_arr: this.state.field_priorty_arr.concat(this.state.field_priorty),select_options_arr: this.state.select_options_arr.concat(this.state.select_options),field_required_arr: this.state.field_required_arr.concat(valuerequired)
			  });
			  this.setState({showlayout: true},function(){
			  this.setState({form_field_name:'',form_input_type:'',form_input_type_value:'',field_required_checked:true,field_priorty:'',select_options:''});
			  this.getformlayout();
			  })
		}
	}
	handleFormSubmit = () => {
		console.log('rtest')
		this.setState({Loading:true});
		 const config = {
			headers: {
			  'Content-Type': 'multipart/form-data'
			}
		  };

		const formPayload = this.state;
		var qs = require('qs');
		var status = '';
		if(formPayload.selectedvalue === '' && formPayload.selectedvalue!== 0){
		status =formPayload.selectedOption.value;
		}else{
		status = formPayload.selectedvalue;
		}
		
		var column_value = '';
		if(formPayload.form_one_column == true){
			column_value = 1;
		}else{
			column_value = 2;
		}
		var postObject = {
			admin_id : localStorage.getItem("admin_id"),
			form_name: formPayload.form_name,
			form_column: column_value,
			form_field_name_arr: formPayload.form_field_name_arr,
			form_input_type_value_arr: formPayload.form_input_type_value_arr,
			field_priorty_arr: formPayload.field_priorty_arr,
			field_required_arr: formPayload.field_required_arr,
			select_options:formPayload.select_options_arr,
			type: formPayload.type,
			status      :status				
		};
		axios.post(apiUrl + "formbuilder/add", postObject, {headers: {'Content-Type': 'multipart/form-data'}}).then(res => {
			if(res.data.status === "success"){
				this.setState({ Loading: false });

				const formpayload = res.data.rehablist;
				
				$('.success_message').html('<div class="status_sucess"><h3>'+ res.data.message+'</h3></div>');


				setTimeout(
					function() {
						this.props.navigate('/formbuilder');
					
					}
					.bind(this),
					3000
					);
			} else {
				
				this.setState({ Loading: false });
				$('.success_message').html('<div class="status_sucess"><h3>'+ res.data.message+'</h3></div>');
					setTimeout(
					function() {
						$('.success_message').html('');
					}
					.bind(this),
					3000
					);
			}
		});
		//this.props.getAddForm(qs.stringify(postObject));

}
	validateFormfield() {
		const {form_field_name,form_input_type_value,field_required,field_priorty,select_options} = this.state;
		let errors = 0;
	
		if (!form_field_name) {
			errors++;
			$('.errorfieldname').html('<span class="errorspan">Please fill the field</span>');
		}else if(form_field_name){
			$('.errorfieldname').html('');
		}


		if (!form_input_type_value) {
			errors++;
			$('.errortype').html('<span class="errorspan">Please select input type</span>');
		}else if(form_input_type_value){
			$('.errortype').html('');
		}

		if(!field_priorty){
		errors++;
			$('.errorpriority').html('<span class="errorspan">Please fill the field</span>');
		}else if(field_priorty){
			$('.errorpriority').html('');
		}

		if(form_input_type_value == 'select' || form_input_type_value == 'checkbox' || form_input_type_value == 'radio'){
			if(!select_options){
				errors++;
				$('.erroroptions').html('<span class="errorspan">Please fill the field</span>');
			}else if(select_options){
				$('.erroroptions').html('');
			}
		}

		if(errors>0) {
		setTimeout(function () {
		scrollToTopValidate();
		}, 100);
		return false;
		}
		else {
		return true;
		}

    }
  render() {
	const navigate = this.props.navigate;
  	  const {selectedOption} = this.state;
		return (
			<div className="wrapper"> 
			 <Header />
			 <MenuSidebar currentpage="formbuilder" />  
				 <div className="content">	
					 <div className="content-wrapper">
					 <div class="content-wrapper-before"></div>
					 <div className="form-wrapper leaa-admin-head card">
						 <div className="success_message"></div>
						 <div className="back-btn-lux">
                  	<a onClick={() => navigate(-1)}>Back</a>    
            	</div>
							 <div className="title">
								 <h4>Add Form</h4>
							 </div>
						 <div className="content-body">
					   
							 <div className="builderpage-module-pagewrapper custom-wrapper">
						  <section className="builderpage-module-section custom-section" >
							 {(this.state.showlayout == true)?<> <h2 className="typography-module-title">Form Field
								<div className="sortablecontainer-module-delete-btn"><button onClick={this.getdeleteall}>Delete All</button></div>
							 </h2>
								<div className="sortablecontainer-module-wrapper">
								  <ul>
									{this.getformlayout()}
								  </ul>
								 
							   </div></>:''}
						   </section>
					 <form className="BuilderPage-module-form builderpage-module-section" onSubmit={ (e) => { e.preventDefault(); this.handleFormSubmit(this); }}>
		 
						   <div className="form-group">
					 <h2 className="typography-module-title add-menu-title">Field Creator</h2>

							   <label>Form Name:</label>
							   <input type="text" className="form-control" placeholder="Form Name" name="form_name" value={this.state.form_name} onChange={this.handleInputChange} autoComplete="off"/>
							 </div>
							 <div className="form-group radio-check">
							 <label>Choose layout</label>
									 <input className="form-check-input" type="radio" name="form_column" id="form_one_column" checked/>
									 <label className="form-check-label" for="form_one_column">
									 One Column
									 </label>
									 <input className="form-check-input" type="radio" name="form_column" id="form_two_column" />
									 <label className="form-check-label" for="form_two_column">
									  Two Column
									 </label>
							 </div>
							 <div className="form-group">					
							 <label>Status:</label>
									<Select 
									value={selectedOption?selectedOption:{ value: 'active', label: 'Active'}}
									 options={lang.common.status_option} 
									 onChange={this.handleChange}
									 />
						 </div>
						   <div className="form-group">
						   <label>Name:</label>
							 <input type="text" name="form_field_name" className="form-control" value={this.state.form_field_name} placeholder="Field Name" onChange={this.handleInputChange} autoComplete="off" />
						 <div className="errorfieldname"></div>
						   </div>
						 <div className="form-group">
						   <label>Type: </label>
						   <Select 
						   value={this.state.form_input_type!=''?this.state.form_input_type:'Choose'}
						   options={lang.common.field_options} 
						   onChange={this.handlefieldTypeChange.bind(this)}
						   placeholder= 'Field Type'
						   isSearchable={false}
						   />
							 <div className="errortype"></div>
						 </div>
						 {(this.state.form_input_type_value == 'select' || this.state.form_input_type_value == 'checkbox' || this.state.form_input_type_value == 'radio')?<div className="form-group">
						 <label>Options:</label>
						 <input type="text" name="select_options" className="form-control" value={this.state.select_options} placeholder="Enter options separate by ," onChange={this.handleInputChange} autoComplete="off" />
						 <div className="erroroptions"></div>
						 </div>:''}
						 <div className="form-group">
								 <label>Field Required : </label>
								 <input onChange={this.handleInputChange} type="checkbox"  name="field_required" checked={this.state.field_required_checked}/><span>Yes</span>
						</div>
						 <div className="form-group">
								 <label>Priority : </label>
								  <input type="text" name="field_priorty" className="form-control" value={this.state.field_priorty} placeholder="Field Priority" onChange={this.handleInputChange} autoComplete="off" />
								  <div className="errorpriority"></div>
						</div>
					   <div className="submit-button">
						  <span className="btn btn_orange btn_minwid login_submit animate-btn2" onClick={this.Addtolayout}>Add</span>
					   </div>
					   
					  </form>
				   </div>
				   <div className="submit-center">	
							<button className="btn btn_orange btn_minwid login_submit animate-btn2" type="submit" disabled={(this.state.Loading ===true)?true:false}> {this.state.Loading ===true &&
												 <span className="load-data"></span> 
												 }Submit
							</button>
					 </div>
					   </div>	
				   </div>

				   </div>	
			   </div>
		   </div>
		   );
  }
}



export default withRouter(Add);